import * as React from 'react';

import { PageNotFoundErrorPage } from '@hub-fe/app/ErrorPages';
import IsBrowser from '@hub-fe/common/IsBrowser';
import { DamlHubHelmet } from '@hub-fe/landing/nav';

const NotFoundPage: React.FC = () => (
    <IsBrowser>
        <DamlHubHelmet />
        <PageNotFoundErrorPage />
    </IsBrowser>
);

// Needed for Gatsby
// ts-unused-exports:disable-next-line
export default NotFoundPage;
